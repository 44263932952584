<template>
    <div>
        <el-input v-model="searchObj.serialNumber" style="width:400px;" @change="search" placeholder="模糊查询">
            <template slot="prepend">序列号</template>
        </el-input> <el-button type="primary" @click="search">搜索</el-button>
        <vxe-table :data="tableData" border :loading="loading" style="margin-top:10px;">
            <vxe-column field="serialNumber" title="序列号"></vxe-column>
            <vxe-column field="command81" title="command_80_81"></vxe-column>
        </vxe-table>
        <vxe-pager align="left" @page-change="getList" :page-size.sync="searchObj.pageSize" :current-page.sync="searchObj.currentPage" :total="searchObj.total" :layouts="['PrevPage', 'Jump', 'PageCount', 'NextPage', 'Total']">
        </vxe-pager>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            tableData: [],
            searchObj: {
                serialNumber: "",
                currentPage: 1,
                pageSize: 10,
                total: 0,
            },
        };
    },
    methods: {
        getList() {
            let that = this;
            that.loading = true;
            that.axios.post("Base_Device_80_81/GetList", that.searchObj).then((response) => {
                that.tableData = response.data.data.data;
                that.searchObj.total = response.data.data.total;
                that.loading = false;
            });
        },
        search() {
            let that = this;
            that.searchObj.currentPage = 1;
            that.getList();
        }
    },
    mounted() {
        let that = this;
        that.getList();
    }


};
</script>

<style></style>